import React from 'react';
import Slider from 'react-slick';
import Fade from 'react-reveal/Fade';
import { Link } from 'gatsby';
import Image from '../components/Common/Image';
import './HomeHero.scss';
import HomePageImageQuery from '../components/HomePageImageQuery'

export const HomeHero = (props) => {
  const {
    images,
    heading,
    subheading,
    hasLink,
    link,
    linkLabel,
    siteMetadata,
  } = props;
  const { wordpressUrl } = siteMetadata;
  const splat = hasLink ? link.replace(wordpressUrl, '') : null;
  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    cssEase: 'ease'
  };
  return (
    <section className="home-hero" style={{ backgroundColor: 'lightgrey' }}>
      <ul className="images">
        <div className="home-header-placeholder"><HomePageImageQuery/></div>
        <Slider {...sliderSettings}>
          <li className="image placeholder">
          </li>
          {images.map((slide, index) => {
            const { image } = slide;
            return (
              <li className="image" key={index}>
                <div className="inner-image">
                  <Image image={image} />
                </div>
              </li>
            );
          })}
        </Slider>
      </ul>
      <div className="home-hero-content">
        <div className="wrap">
          <div className="content-wrap">
            <Fade bottom cascade duration={800} ssrReveal>
              <div>
                <div className="abstract-shape-3" />
                <div className="abstract-shape-2" />
                <div className="abstract-shape-1" />
              </div>
            </Fade>
            <Fade duration={900} delay={1200}>
              <div className="text-container">
                <h1 className="heading">{heading}</h1>
                <span className="subheading">{subheading}</span>
              </div>
            </Fade>
          </div>
        </div>
      </div>
      {hasLink && link && (
        <div className="link-container">
          <div className="cream-lines-left" />
          <div className="cream-lines-right" />
          <Link
            to={splat}
            activeClassName="active"
            className="link"
            aria-current="true"
          >
            <span>{linkLabel}</span>
          </Link>
        </div>
      )}
    </section>
  );
};
