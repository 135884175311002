import React from 'react';
import { Link } from 'gatsby';
import Image from '../components/Common/Image';
import './TabLinks.scss';

export const TabLinks = (props) => {
  const {
    links,
    siteMetadata,
  } = props;
  const { wordpressUrl } = siteMetadata;
  return (
    <section className="tab-links">
      {links.map((linkItem, index) => {
        const {
          link,
          image,
          linkLabel,
        } = linkItem;
        const splat = (link !== null) ? link.replace(wordpressUrl, '') : "";
        return (
          <div className="link-container" key={index}>
            <Link
              to={splat}
              className="tab-link"
              aria-current="true"
            >
              <div className="background-image">
                <Image image={image} />
              </div>
              <div className="link-overlay">
                <span>{linkLabel}</span>
              </div>
            </Link>
          </div>
        );
      })}
    </section>
  );
};
