import React from 'react';
import Slider from 'react-slick';
import { Link } from 'gatsby';
import Image from '../components/Common/Image';
import './ImageLinksBlock.scss';

export const ImageLinksBlock = (props) => {
  const {
    links,
    siteMetadata,
  } = props;
  const { wordpressUrl } = siteMetadata;
  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    cssEase: 'ease',
    responsive: [
      {
        breakpoint: 1080
      },
      {
        breakpoint: 99999,
        settings: 'unslick'
      }
    ]
  };
  return (
    <section className="image-links-block">
      <ul className="link-tiles">
        <Slider {...sliderSettings}>
          {links.map((item, index) => {
            const {
              link,
              linkLabel,
              image,
            } = item;
            const splat = (link !== null) ? link.replace(wordpressUrl, '') : "";
            return (
              <li className="link-tile" key={index}>
                <Link
                  to={splat}
                  activeClassName="active"
                  className="link"
                  aria-current="true"
                >
                  <Image image={image} />
                  <div className="overlay">
                    <span className="link-label">{linkLabel}</span>
                  </div>
                </Link>
              </li>
            );
          })}
        </Slider>
      </ul>
      {/* <ul className="link-tiles-slider">

          {links.map((link, index) => {
            return (
              <li className="link-tile" key={index}>
                <Link
                  to={link.link}
                  activeClassName="active"
                  className="link"
                  aria-current="true"
                >
                  <div className="inner-image" style={{backgroundImage: `url('${getBackgroundImage(link.image)}')`}} />
                  <div className="overlay">
                    <span className="link-label">{link.linkLabel}</span>
                  </div>
                </Link>
              </li>
            );
          })}
        </Slider>
      </ul> */}
    </section>
  );
};
