import React, { Component } from 'react';
// import { StaticQuery, graphql } from 'gatsby';
// import { slugToTitle } from '../utils/helpers';
// import GatsbyLink from './GatsbyLink';
import { Link } from 'gatsby';
import './Breadcrumbs.scss';

export const Breadcrumbs = (props) => {
  const {
    crumbLabel,
    location,
  } = props;

  if (!location) {
    return null;
  }

  const { pathname, origin, href } = location;
  const pathArray = pathname.split('/');
  let urlBuild = [];
  var regex = /-/gi;

  // console.log(location);

  return (
    <div className="breadcrumbs">
      <div className="inner">
        <ul>
          <li><Link to={'/'}>Home</Link></li>
          {pathArray && pathArray.map((item, index) => {
            // console.log(item);
            if (item) {
              urlBuild.push( item );
              return (
                <li key={index}>
                  <span>&rarr;</span>{item === 'category' ? <Link to={`/blog/`}>Blog</Link> : <Link to={`/${urlBuild.join('/')}/`}>{item.replace(regex, ' ')}</Link> }
                </li>
              )
            }
          })}
        </ul>
      </div>
    </div>
  );
}
