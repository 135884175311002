import React from 'react';
import './ContactIntroBlock.scss';

export const ContactIntroBlock = (props) => {
  const {
    heading,
    text,
    sideHeading
  } = props;
  return (
    <section className="contact-intro-block">
      <div className="wrap">
        <div className="left-col">
          {heading &&
            <h2 className="heading" dangerouslySetInnerHTML={{__html: heading}} />
          }
          <div className="text" dangerouslySetInnerHTML={{__html: text}} />
        </div>
        <div className="right-col">
          <div className="side-heading-container">
            <span className="side-heading">On budget. On time. Every time.</span>
          </div>
        </div>
      </div>
    </section>
  );
};
