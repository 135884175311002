import React from 'react';
import { Link } from 'gatsby';
import Image from '../Image';
import { Title } from '../Content/Title';

export const ProjectListItem = (props) => {
  const {
    title,
    slug,
    featuredImage,
    acf
  } = props;
  const {
    shortDescription
  } = acf;
  const shortenedTitle = title.length > 70 ? `${title.substr(0, 60)} ...` : title;
  const strippedDescription = shortDescription.replace(/(<([^>]+)>)/ig, '');
  const shortenedDescription = strippedDescription.split(' ').slice(0, 24).join(' ').concat('...');
  return (
    <article className="post-entry">
      <Link to={`/project-gallery/${slug}/`}>
        <div className="featured-image">
          <Image image={featuredImage} />
        </div>
        <div className="entry-content-wrap">
          <Title title={shortenedTitle} tag="h3" />
          {shortDescription && (
            <div className="description">
              <p>{shortenedDescription}</p>
            </div>
          )}
          <span className="read-more">Read more</span>
        </div>
      </Link>
    </article>
  );
};
