import React from 'react';
import { Link } from 'gatsby';
import Image from '../components/Common/Image';
import './ServiceLinksBlock.scss';

export const ServiceLinksBlock = (props) => {
  const {
    links,
    siteMetadata,
  } = props;
  const { wordpressUrl } = siteMetadata;
  return (
    <section className="service-links-block">
      <div className="wrap">
        <ul className="links">
          {links.map((linkItem, index) => {
            const {
              link,
              icon,
              heading,
              text,
            } = linkItem;
            const splat = link.replace(wordpressUrl, '');
            return (
              <li className="link" key={index}>
                <Link to={splat}>
                  <div className="link-icon">
                    <Image image={icon} className="contain" />
                  </div>
                  <span className="link-heading">{heading}</span>
                  <div className="overlay">
                    <div className="text-wrap">
                      <div className="text" dangerouslySetInnerHTML={{__html: text}} />
                      <span className="read-more">Read more</span>
                    </div>
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};
