import React from 'react';
import ParallaxElement from '../components/Common/ParallaxElement';
import Plyr from '../components/Plyr/Plyr';
import './VideoBlock.scss';

export const VideoBlock = (props) => {
  const {
    heading,
    videoUrl,
    videoProvider
  } = props;
  return (
    <section className="video-block">
      <div className="wrap">
        <span className="heading">{heading}</span>
        <Plyr
          videoProvider={videoProvider}
          videoUrl={videoUrl}
        />
        <ParallaxElement className="abstract-shapes" />
      </div>
    </section>
  );
};
