import React from 'react';
import { graphql } from 'gatsby'
import { Categories } from '../Common/Categories';
import { PostListItem } from './PostListItem';
import BlogPagination from '../BlogPagination';
import './PostList.scss';

export const PostList = (props) => {
  const {
    postsOnPage,
    categories,
    pageContext,
    pathPrefix,
    categorySlug,
  } = props;
  const {
    edges
  } = postsOnPage;
  const postItems = edges.map(postItem => postItem.node);
  return (
    <section className="posts">
      {categories &&
        <Categories categories={categories} categorySlug={categorySlug} />
      }
      <section className="posts-list">
        <div className="wrap">
          {(!postItems || postItems.length === 0) && <h3 className="no-posts-message">No Posts Found</h3>}
          {(postItems && postItems.length > 0) && postItems.map(post => <PostListItem key={post.slug} {...post} />)}
        </div>
      </section>
      <BlogPagination
        pageContext={pageContext}
        pathPrefix={pathPrefix}
      />
    </section>
  );
};

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    featuredImage: featured_media {
      localFile {
        childImageSharp {
          fluid(maxWidth: 600, quality: 70) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
    title
    excerpt
    slug
    categories {
      name
      slug
    }
  }
`
