import React from 'react';
import './ThreeColumnTextBlock.scss';

const renderColumnContent = (contentType, text, points) => {
  if (contentType === 'text' && text) {
    return <div className="text" dangerouslySetInnerHTML={{__html: text}} />;
  }
  if (contentType === 'points' && points) {
    return (
      <ul className="points">
        {points.map((point, index) => {
          return <li className="point" key={index}>{point.point}</li>;
        })}
      </ul>
    );
  }
  return null;
};

export const ThreeColumnTextBlock = (props) => {
  const {
    theme,
    columnOneHeading,
    columnOneContentType,
    columnOneText,
    columnOnePoints,
    columnTwoHeading,
    columnTwoContentType,
    columnTwoText,
    columnTwoPoints,
    columnThreeHeading,
    columnThreeContentType,
    columnThreeText,
    columnThreePoints
  } = props;
  return (
    <section className={`three-column-text-block ${theme}`}>
      <div className="wrap">
        <div className="column col-1">
          {columnOneHeading &&
            <span className="heading">{columnOneHeading}</span>
          }
          {renderColumnContent(columnOneContentType, columnOneText, columnOnePoints)}
        </div>
        <div className="column col-2">
          {columnTwoHeading &&
            <span className="heading">{columnTwoHeading}</span>
          }
          {renderColumnContent(columnTwoContentType, columnTwoText, columnTwoPoints)}
        </div>
        <div className="column col-3">
          {columnThreeHeading &&
            <span className="heading">{columnThreeHeading}</span>
          }
          {renderColumnContent(columnThreeContentType, columnThreeText, columnThreePoints)}
        </div>
      </div>
    </section>
  );
};
