import React from 'react';
import Slider from 'react-slick';
import { Link } from 'gatsby';
import Image from '../components/Common/Image';
import './SliderBlock.scss';

export const SliderBlock = (props) => {
  const {
    heading,
    subheading,
    slides,
    siteMetadata,
  } = props;
  const { wordpressUrl } = siteMetadata;
  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: 'ease'
  };
  return (
    <section className="slider-block">
      <div className="heading-container">
        <div className="wrap">
          <h2 className="heading">{heading}</h2>
          <p className="subheading">{subheading}</p>
        </div>
      </div>
      <ul className="slides">
        <Slider {...sliderSettings}>
          {slides.map((slide, index) => {
            const {
              heading: slideHeading,
              points,
              hasLink,
              link,
              linkLabel,
              image,
            } = slide;
            const splat = hasLink ? link.replace(wordpressUrl, '') : null;
            return (
              <li className="slide" key={index}>
                <div className="text-col">
                  <div className="text-wrapper">
                    <h2 className="slide-heading">{slideHeading}</h2>
                    <ul className="points">
                      {points.map((point, pointIndex) => {
                        const { text } = point;
                        return (
                          <li className="point" key={pointIndex}>{text}</li>
                        );
                      })}
                    </ul>
                    {(hasLink && link) && (
                      <Link
                        to={splat}
                        className="link"
                      >
                        <span>{linkLabel}</span>
                      </Link>
                    )}
                  </div>
                </div>
                <div className="image-col">
                  <div className="inner-image">
                    <Image image={image} />
                  </div>
                </div>
              </li>
            );
          })}
        </Slider>
      </ul>
    </section>
  );
};
