import React from 'react';
import { graphql } from 'gatsby'
import { Categories } from '../Common/Categories';
import { ProjectListItem } from './ProjectListItem';
import BlogPagination from '../BlogPagination';

export const ProjectList = (props) => {
  const {
    projectsOnPage,
    projectCategories,
    pageContext,
    pathPrefix,
    projectCategorySlug,
  } = props;
  const {
    edges = []
  } = projectsOnPage;
  const projectItems = (edges && edges.length > 0) ? projectsOnPage.edges.map(postItem => postItem.node) : [];
  return (
    <section className="projects">
      {/* {projectCategories &&
        <Categories categories={projectCategories} categorySlug={projectCategorySlug} />
      } */}
      <section className="projects-list">
        <div className="wrap">
          {!projectItems || projectItems.length < 1 ? <h3 className="no-projects-found">No projects found</h3> : null}
          {projectItems.map(project => <ProjectListItem key={project.slug} {...project} />)}
        </div>
      </section>
      <BlogPagination
        pageContext={pageContext}
        pathPrefix={pathPrefix}
      />
    </section>
  );
};

export const pageQuery = graphql`
  fragment ProjectListFields on wordpress__wp_project {
    id
    featuredImage: featured_media {
      source_url
      localFile {
        childImageSharp {
          fluid(maxWidth: 600, quality: 70) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
    title
    slug
    acf {
      projectName
      projectLocation
      projectType
      costPerSquareMetre
      floorArea
      duration
      shortDescription
      jobDescription
    }
  }
`
