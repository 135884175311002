import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import './Forms.scss';

export default class ContactForm extends Component {
  render() {
    const { theme, successUrl } = this.props;

    let recaptchaValue = null;

    function onChange(value) {
      recaptchaValue = value;
    }

    function onSubmit(e) {
      if(!recaptchaValue) {
        e.preventDefault();
        alert('Please complete reCAPTCHA');
      }
    }

    return (
      <form
        className={`form${theme ? ` ${theme}` : ''}`}
        name="contact"
        method="POST"
        action="https://api.formbucket.com/f/buk_dMESvnCQ9s1QTJsCZV76IJOY"
        onSubmit={onSubmit}
      >
        <input name="thank_you_url" value={successUrl} type="hidden" />
        <input type="hidden" name="form-name" value="contact" />
        <label className="field full-width" htmlFor="name">
          <input type="text" id="name" name="name" placeholder="Your Name*" required />
        </label>
        <label className="field full-width" htmlFor="email">
          <input type="email" id="email" name="email" placeholder="Email*" required />
        </label>
        <label className="field full-width" htmlFor="phone">
          <input
            type="tel"
            pattern="^\+?\d{0,13}"
            id="phone"
            name="phone"
            placeholder="Phone*"
            required
          />
        </label>
        <label className="field full-width" htmlFor="message">
          <textarea id="message" name="message" placeholder="Message" rows="3" />
        </label>
        <div className="contact-form-recaptcha-container">
          <ReCAPTCHA
            sitekey="6Lc_86wUAAAAAFCSLB_vRmRYihTlQNpgJtYe-lIz"
            onChange={onChange}
          />
        </div>
        <button className="send-button" type="submit" aria-label="Send">
          <span>Send</span>
        </button>
      </form>
    );
  }
}
