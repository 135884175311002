import React from 'react';
import './SocialShareBlock.scss';

const baseURL = ''; // NOTE: FIX ME

export const SocialShareBlock = (props) => {
  const { location, position } = props;
  return (
    <section className="social-share-block">
      <div className={`wrap ${position}`}>
        <span className="heading">SHARE</span>
        <ul className="share-links">
          <li className="link facebook">
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${baseURL}${location.pathname}`}>
              Facebook
            </a>
          </li>
          <li className="link linkedin">
            <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${baseURL}${location.pathname}`}>
              LinkedIn
            </a>
          </li>
          <li className="link pinterest">
            <a href={`https://pinterest.com/pin/create/button/?url=${baseURL}${location.pathname}&media=&description=`}>
              Pinterest
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
};
