import React from 'react';
import { Link } from 'gatsby';
import Fade from 'react-reveal/Fade';
import ParallaxElement from '../components/Common/ParallaxElement';
import './StandardTextBlock.scss';

export const StandardTextBlock = (props) => {
  const {
    theme,
    mainHeading,
    text,
    hasLink,
    link,
    linkLabel,
    siteMetadata,
  } = props;
  const { wordpressUrl } = siteMetadata;
  const splat = hasLink ? link.replace(wordpressUrl, '') : null;
  return (
    <section className={`standard-text-block ${theme}`}>
      {theme === 'dark' && (
        <ParallaxElement
          className="grey-lines"
          speed="-2"
        />
      )}
      <div className="wrap">
        <div className="heading-left">
          <div className="side-heading-container">
            { theme === 'dark' &&
              <span className="side-heading-prefix">Your</span>
            }
            <span className="side-heading">
              {theme === 'dark' ? 'Complete Turnkey Solution' : 'On budget. On time. Every time.'}
            </span>
          </div>
        </div>
        <div className="text-right">
          {mainHeading &&
            <h2 className="main-heading" dangerouslySetInnerHTML={{__html: mainHeading}} />
          }
          <Fade>
            <div className="text" dangerouslySetInnerHTML={{__html: text}} />
          </Fade>
          {(hasLink && link) && (
            <Link to={splat} className="link">
              <span>{linkLabel}</span>
            </Link>
          )}
        </div>
      </div>
    </section>
  );
};
