import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Slider from 'react-slick';
import Image from '../components/Common/Image';
import './LogosBlock.scss';

const LogosBlockTemplate = (props) => {
  const {
    heading,
    usingGlobalLogos,
    logos,
    data,
  } = props;
  const { siteSettings } = data;
  const { options } = siteSettings;
  const { clientLogos } = options;
  const activeLogos = usingGlobalLogos ? clientLogos : logos;
  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 750
      },
      {
        breakpoint: 99999,
        settings: 'unslick'
      }
    ]
  };
  return (
    <section className="logos-block">
      <div className="wrap">
        <span className="heading">{heading}</span>
        <ul className="logos">
          <Slider {...sliderSettings}>
            {activeLogos && activeLogos.map((item, index) => {
              const { logo } = item;
              return (
                <li className="logo-image" key={index}>
                  <div className="inner-image">
                    <Image image={logo} className="contain" />
                  </div>
                </li>
              );
            })}
          </Slider>
        </ul>
      </div>
    </section>
  );
};

export const LogosBlock = (props) => (
  <StaticQuery
    query={graphql`
      query {
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            clientLogos {
              logo {
                id
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
                alt_text
              }
            }
          }
        },
      }
    `}
    render={data => <LogosBlockTemplate data={data} {...props} />}
  />
);
