import React from 'react';
import Slider from 'react-slick';
import { Link } from 'gatsby';
import Image from '../components/Common/Image';
import './ShowcaseBlock.scss';
import { Slant } from '../components/Common/Slant';

export const ShowcaseBlock = (props) => {
  const {
    heading,
    text,
    hasShowcaseHeading,
    showcaseHeading,
    showcaseItems,
    hasSlant,
    slantColor,
    slantDirection,
    siteMetadata,
  } = props;
  const { wordpressUrl } = siteMetadata;
  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1080
      },
      {
        breakpoint: 99999,
        settings: 'unslick',
        unslick: true
      }
    ]
  };
  return (
    <section className="showcase-block">
      <div className="wrap">
        {(heading || text) && (
          <div className="text-container">
            <h2 className="heading" dangerouslySetInnerHTML={{ __html: heading }} />
            <div className="text" dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        )}
        <div className="showcase">
          { hasShowcaseHeading && (
            <div className="showcase-heading">
              <span dangerouslySetInnerHTML={{ __html: showcaseHeading }} />
            </div>
          )}
          <div className="showcase-items">
            <Slider {...sliderSettings}>
              {showcaseItems.map((item, index) => {
                const {
                  image,
                  heading: itemHeading,
                  subheading,
                  hasLink,
                  link
                } = item;
                const splat = hasLink ? link.replace(wordpressUrl, '') : null;
                return (
                  (hasLink && link) ? (
                    <Link
                      className="showcase-item"
                      to={splat}
                      key={index}
                    >
                      <div className="background-image">
                        <Image image={image} />
                      </div>
                      <div className="overlay">
                        <div className="item-content">
                          <span className="item-heading">{itemHeading}</span>
                          <span className="item-subheading">{subheading}</span>
                          <Link className="item-cta" to={splat}><span>View project</span></Link>
                        </div>
                      </div>
                    </Link>
                  ) : (
                    <div className="showcase-item" key={index}>
                      <div className="background-image">
                        <Image image={image} />
                      </div>
                      {(itemHeading || subheading) && (
                        <div className="overlay">
                          <div className="item-content">
                            <span className="item-heading">{itemHeading}</span>
                            <span className="item-subheading">{subheading}</span>
                          </div>
                        </div>
                      )}
                    </div>
                ));
              })}
            </Slider>
          </div>
        </div>
      </div>
      { hasSlant &&
        <Slant color={slantColor} slantDirection={slantDirection} />
      }
    </section>
  );
};
