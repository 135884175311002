import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import SubscribeForm from '../components/Forms/SubscribeForm';
import './SubscribeFormBlock.scss';

export const SubscribeFormBlockTemplate = (props) => {
  const {
    data,
  } = props;
  const { siteSettings } = data;
  const { options } = siteSettings;
  const {
    subscribeFormHeading
  } = options;
  return (
    <section className="subscribe-form-block">
      <div className="wrap">
        <span className="heading">{subscribeFormHeading}</span>
        <div className="form">
          <SubscribeForm />
        </div>
      </div>
    </section>
  );
};


export const SubscribeFormBlock = (props) => (
  <StaticQuery
    query={graphql`
      query {
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            subscribeFormHeading
          }
        },
      }
    `}
    render={data => <SubscribeFormBlockTemplate data={data} {...props} />}
  />
);
