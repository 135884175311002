import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
// import GoogleMapReact from 'google-map-react';
import Fade from 'react-reveal/Fade';
import Image from '../components/Image';
import './ContactInformationBlock.scss';

// const isClient = typeof window !== 'undefined';

export const ContactInformationBlockTemplate = (props) => {
  const {
    data,
  } = props;
  const { siteSettings, googleMapImage } = data;
  const mapImageObj = {
    localFile: googleMapImage
  };
  const { options } = siteSettings;
  const {
    // address,
    addressPostal,
    emailAddress,
    phoneNumber,
    // googleMapsApiKey
  } = options;
  // const lat = parseFloat(address.lat);
  // const lng = parseFloat(address.lng);
  return (
    <section className="contact-information-block">
      <div className="contact-info">
        <Fade>
          <div className="flex-wrap">
            <span className="email-label">Email</span>
            <a href={`mailto:${emailAddress}`} className="email">{emailAddress}</a>
            <span className="phone-label">Phone</span>
            <a href={`tel:${phoneNumber}`} className="phone">{phoneNumber}</a>
            <span className="office-label">Office</span>
            <p className="office">{addressPostal}</p>
          </div>
        </Fade>
      </div>
      <div className="map">
        {/* { isClient && (
          <GoogleMapReact
            bootstrapURLKeys={{ key: googleMapsApiKey }}
            defaultCenter={[lat, lng]}
            defaultZoom={14}
          />
        )} */}
        <Image image={mapImageObj} />
        {/* <img src={GoogleMapImage} alt={`A map leading to ${addressPostal}`} /> */}
      </div>
    </section>
  );
}

export const ContactInformationBlock = (props) => (
  <StaticQuery
    query={graphql`
      query {
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            address {
              address
              lat
              lng
            },
            addressPostal,
            emailAddress,
            phoneNumber,
            googleMapsApiKey
          }
        },
        googleMapImage: file(relativePath: { eq: "images/GoogleMap.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => <ContactInformationBlockTemplate data={data} {...props} />}
  />
);
