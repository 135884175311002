import React, { Component } from 'react';

export default class BrochureForm extends Component {
  render() {
    const { theme, successUrl } = this.props;
    return (
      <form
        className={`contact-form${theme ? ` ${theme}` : ''}`}
        name="contact"
        method="POST"
        action="https://api.formbucket.com/f/buk_vR1E2wwzdhs1GPlnKNSGDTCC"
      >
        <input name="thank_you_url" value={successUrl} type="hidden" />
        <input type="hidden" name="form-name" value="contact" />
        <label className="field full-width" htmlFor="name">
          <input type="text" id="name" name="name" placeholder="Your Name*" required />
        </label>
        <label className="field full-width" htmlFor="phone">
          <input
            type="tel"
            pattern="^\+?\d{0,13}"
            id="phone"
            name="phone"
            placeholder="Phone*"
            required
          />
        </label>
        <label className="field full-width" htmlFor="email">
          <input type="email" id="email" name="email" placeholder="Business Email*" required />
        </label>
        <div data-netlify-recaptcha="true" />
        <button className="send-button" type="submit" aria-label="Send">
          <span>Send Me My Brochure Now</span>
        </button>
      </form>
    );
  }
}
