import React from 'react';
import { Link } from 'gatsby';

export const Categories = (props) => {
  const {
    categorySlug,
    categories,
  } = props;
  if (!categories) {
    return <span />;
  }
  const categoryItems = (categories && categories.edges) ? categories.edges.map(categoryItem => categoryItem.node) : null;
  return (
    <nav className="categories">
      <div className="wrap">
        <span className="filter-by">Filter by</span>
        <ul>
          <li>
            <span>All</span>
          </li>
          {categoryItems.map(category => (
            <li key={category.slug}>
              <Link to={`/${categorySlug}/${category.slug}/`}>
                {category.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};
