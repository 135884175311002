import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import GoogleMapReact from 'google-map-react';
import './MapBlock.scss';

const isClient = typeof window !== 'undefined';

const MapBlockTemplate = (props) => {
  const {
    map,
    data,
  } = props;
  const { siteSettings } = data;
  const { options } = siteSettings;
  const { googleMapsApiKey } = options;
  const lat = parseFloat(map.lat);
  const lng = parseFloat(map.lng);
  return (
    <section className="map-block">
      <div className="map">
        {isClient && (
          <GoogleMapReact
            bootstrapURLKeys={{ key: googleMapsApiKey }}
            defaultCenter={[lat, lng]}
            defaultZoom={14}
          >
            <div
              className="marker"
              lat={lat}
              lng={lng}
            />
          </GoogleMapReact>
        )}
      </div>
    </section>
  );
};

export const MapBlock = (props) => (
  <StaticQuery
    query={graphql`
      query {
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            googleMapsApiKey
          }
        },
      }
    `}
    render={data => <MapBlockTemplate data={data} {...props} />}
  />
);
