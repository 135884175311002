import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

export default (props) => (
  <StaticQuery
    query={graphql`
      query HomePageImageQuery {
        wordpressPage(path: {eq: "/"}) {
          acf {
            background {
              localFile {
                childImageSharp {
                  base64: sizes(base64Width: 2000, quality: 100) {
                    base64
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data?.wordpressPage?.acf?.background?.localFile?.childImageSharp?.base64?.base64;
      return (
        <img src={imageData} alt="" />
      )
    }
    }
  />
)
