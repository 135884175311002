import React from 'react';
import { Link } from 'gatsby';
import Image from '../components/Common/Image';
import './StandardHero.scss';
import { Breadcrumbs } from '../components/Breadcrumbs'

export const StandardHero = (props) => {
  const {
    image,
    heading,
    hasText,
    text,
    hasLink,
    link,
    linkLabel,
    siteMetadata,
    location,
  } = props;
  const { wordpressUrl } = siteMetadata;
  const splat = hasLink ? link.replace(wordpressUrl, '') : null;
  return (
    <>
    <section className={`standard-hero ${hasLink ? 'has-link' : ''}`}>
      <Image image={image} />
      <div className="dark-gradient" />
      <div className="wrap">
        {/* <Breadcrumbs location={location} /> */}
        <h1 className="heading">{heading}</h1>
        {hasText && <div className="text" dangerouslySetInnerHTML={{__html: text}} />}
      </div>
      {(hasLink && link) && (
        <div className="link-container">
          <Link
            to={splat}
            activeClassName="active"
            className="link"
            aria-current="true"
          >
            <div className="cream-lines-left" />
            <div className="cream-lines-right" />
            <span>{linkLabel}</span>
          </Link>
        </div>
      )}
    </section>
    <section className="breadcrumbs-container">
      <div className="wrap">
        <Breadcrumbs location={location} />
      </div>
    </section>
    </>
  );
};
