import React from 'react';
import Fade from 'react-reveal/Fade';
import Image from '../components/Common/Image';
import './FullImageTextBlock.scss';

export const FullImageTextBlock = (props) => {
  const {
    theme,
    image,
    textPosition,
    heading,
    text
  } = props;
  return (
    <section className="full-image-text-block">
      <div className="inner-image">
        <Image image={image} />
      </div>
      <div className="image-text-wrap">
        <Image className="background-image" image={image} />
        <div className={`wrap ${textPosition}`}>
          {(heading || text) && (
            <div className={`inner-text-container ${theme}`}>
              {heading && <h2 className="heading">{heading}</h2>}
              {text && (
                <Fade>
                  <div className="text" dangerouslySetInnerHTML={{__html: text}} />
                </Fade>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
