import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Image from '../components/Common/Image';
import BrochureForm from '../components/Forms/BrochureForm';
import './ImageFormBlock.scss';

export const ImageFormBlockTemplate = (props) => {
  const {
    image,
    heading,
    subheading,
    data,
  } = props;
  const { siteSettings } = data;
  const { options } = siteSettings;
  const { brochureFile } = options;
  const { source_url: brochureUrl } = brochureFile;
  return (
    <section className="image-form-block">
      <div className="image-col">
        <div className="inner-image">
          <Image image={image} />
        </div>
      </div>
      <div className="form-col">
        <div className="inner-container">
          <div className="form-wrap">
            <span className="heading">{heading}</span>
            <span className="subheading">{subheading}</span>
            <div className="form">
              <BrochureForm successUrl={brochureUrl} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const ImageFormBlock = (props) => (
  <StaticQuery
    query={graphql`
      query {
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            brochureFile {
              source_url
            }
          }
        },
      }
    `}
    render={data => <ImageFormBlockTemplate data={data} {...props} />}
  />
);
