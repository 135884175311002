import React from 'react';
import { Link } from 'gatsby';
import Image from '../Image';
import { Title } from '../Content/Title';
import { RenderContent } from '../Content/RenderContent';

const getShortenedTitle = (title) => title.length > 70 ? `${title.substr(0, 60)} ...` : title;
const getShortenedExcerpt = (excerpt) => excerpt.replace(/(<([^>]+)>)/ig, '').split(' ').slice(0, 24).join(' ');

export const PostListItem = (props) => {
  const {
    title,
    excerpt,
    slug,
    featuredImage
  } = props;
  return (
    <article className="post-entry">
      <Link to={`/blog/${slug}/`} aria-label={`To post ${getShortenedTitle(title)}`}>
        <div className="featured-image">
          <Image image={featuredImage} />
        </div>
        <div className="entry-content-wrap">
          <Title title={getShortenedTitle(title)} tag="h3" />
          <RenderContent content={getShortenedExcerpt(excerpt)} />
          <span className="read-more">Read more</span>
        </div>
      </Link>
    </article>
  );
};
