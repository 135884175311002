import React from 'react';
import { Link } from 'gatsby';
import { Slant } from '../components/Common/Slant';
import Image from '../components/Common/Image';
import './NewsBlock.scss';

export const NewsBlock = (props) => {
  const {
    heading,
    items,
    hasSlant,
    slantColor,
    slantDirection,
    siteMetadata,
  } = props;
  const { blogSlug, wordpressUrl } = siteMetadata;
  return (
    <section className="news-block">
      <div className="wrap">
        {heading &&
          <span className="heading">{heading}</span>
        }
        <ul className="news-items">
          {items.map((item, index) => {
            const {
              link,
              image,
              heading: itemHeading,
              text,
            } = item;
            const splat = (link !== null) ? link.replace(wordpressUrl, '') : "";
            return (
              <li className="news-item" key={index}>
                <Link
                  to={splat}
                  activeClassName="active"
                  className="link"
                  aria-current="true"
                >
                  <div className="inner-image">
                    <Image image={image} />
                  </div>
                  <div className="text-panel">
                    <div className="text-container">
                      <span className="item-heading">{itemHeading}</span>
                      <span className="text" dangerouslySetInnerHTML={{__html: text}} />
                    </div>
                    <span className="read-more">Read more</span>
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
      { hasSlant &&
        <Slant color={slantColor} slantDirection={slantDirection} />
      }
    </section>
  );
};
