import React from 'react'
import { Link } from 'gatsby'
import './BlogPagination.scss';


const BlogPagination = ({ pageContext, pathPrefix }) => {
  if (!pageContext) return null;
  const { humanPageNumber: currentPageNumber, numberOfPages } = pageContext;
  const pageNumbersArray = Array.from(Array(numberOfPages).keys()).map((val, index) => index + 1);
  if (numberOfPages === 1) return null;
  return (
    <nav className="pagination" role="navigation">
      <div className="wrap">
        <ul className="pages">
          {pageNumbersArray.map((pageNumber) => {
            if (pageNumber === currentPageNumber) {
              return (
                <li className="page-num active" key={pageNumber}>
                  <span>{pageNumber}</span>
                </li>
              );
            }
            return (
              <li className="page-num" key={pageNumber}>
                <Link
                  to={pageNumber === 1 ? `${pathPrefix}/` : `${pathPrefix}/page/${pageNumber}/`}
                  aria-label={`Blog Page ${pageNumber}`}
                >
                  {pageNumber}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  )
}

export default BlogPagination
