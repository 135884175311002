import React from 'react';
import { ProjectList } from '../components/Projects/ProjectList';
import './ProjectFeed.scss';

export const ProjectFeed = (props) => {
  const {
    projectsOnPage,
    projectCategories,
    pageContext,
    siteMetadata,
  } = props;
  const { projectGallerySlug, projectCategorySlug } = siteMetadata;
  const pathPrefix = `/${projectGallerySlug}`
  return (
    <ProjectList
      projectsOnPage={projectsOnPage}
      projectCategories={projectCategories}
      pageContext={pageContext}
      pathPrefix={pathPrefix}
      projectCategorySlug={projectCategorySlug}
    />
  );
}
