import React from 'react';
import { PostList } from '../components/Posts/PostList';
import './BlogFeed.scss';

// eslint-disable-next-line
export const BlogFeed = (props) => {
  const {
    postsOnPage,
    categories,
    pageContext,
    siteMetadata,
  } = props;
  const { blogSlug, categorySlug } = siteMetadata;
  const pathPrefix = `/${blogSlug}`
  return (
    <PostList
      postsOnPage={postsOnPage}
      categories={categories}
      pageContext={pageContext}
      pathPrefix={pathPrefix}
      categorySlug={categorySlug}
    />
  );
}
