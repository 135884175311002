import React, { Component } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Slider from 'react-slick';
import './TestimonialsBlock.scss';

class TestimonialsBlockTemplate extends Component {
  render() {
    const {
      // heading,
      hasLink,
      link,
      linkLabel,
      data,
      siteMetadata,
    } = this.props;
    const { wordpressUrl } = siteMetadata;
    const splat = hasLink ? link.replace(wordpressUrl, '') : null;
    const { siteSettings } = data;
    const { options } = siteSettings;
    const { testimonials } = options;
    const sliderSettings = {
      dots: true,
      arrows: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 6000,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <section className={`testimonials-block ${hasLink ? 'has-link' : ''}`}>
        {hasLink && link && (
          <div className="testimonials-link">
            <Link
              to={splat}
              activeClassName="active"
              className="link"
              aria-current="true"
            >
              <div className="cream-lines-left" />
              <div className="cream-lines-right" />
              <span>{linkLabel}</span>
            </Link>
          </div>
        )}
        <div className="wrap">
          <div className="heading">
            <span>Testimonials</span>
          </div>
          <ul className="testimonials">
            <Slider {...sliderSettings}>
              {testimonials.map((testimonial, index) => {
                return (
                  <li className="testimonial" key={index}>
                    <div className="text" dangerouslySetInnerHTML={{__html: testimonial.text}} />
                    <span className="client-name">{testimonial.clientName}</span>
                    <span className="company-name">{testimonial.companyName}</span>
                  </li>
                );
              })}
            </Slider>
          </ul>
        </div>
      </section>
    );
  }
}

export const TestimonialsBlock = (props) => (
  <StaticQuery
    query={graphql`
      query {
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            testimonials {
              text
              clientName
              companyName
            }
          }
        },
      }
    `}
    render={data => <TestimonialsBlockTemplate data={data} {...props} />}
  />
);
