import React from 'react';
import Fade from 'react-reveal/Fade';
import Image from '../components/Common/Image';
import ParallaxElement from '../components/Common/ParallaxElement';
import './HomeIntro.scss';

export const HomeIntro = (props) => {
  const {
    heading,
    text,
    image
  } = props;
  return (
    <section className="home-intro">
      <ParallaxElement
        className="connector"
        speed="-1.4"
      />
      <div className="wrap">
        <div className="text-container">
          {heading &&
            <h2 className="heading" dangerouslySetInnerHTML={{__html: heading }} />
          }
          <Fade>
            <div className="text" dangerouslySetInnerHTML={{__html: text }} />
          </Fade>
        </div>
        <div className="image-container">
          <div className="image-wrap">
            <ParallaxElement
              className="abstract-shapes"
              speed="1.4"
            />
            <div className="image">
              <Image image={image} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
