import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import ContactForm from '../components/Forms/ContactForm';
import './ContactFormBlock.scss';

const ContactFormBlockTemplate = (props) => {
  const {
    heading,
    subheading,
    contactFormHeading,
    data,
  } = props;
  const { site, siteSettings } = data;
  const { siteMetadata } = site;
  const { siteUrl } = siteMetadata;
  const { options } = siteSettings;
  const {
    addressPostal,
    emailAddress,
    phoneNumber,
  } = options;
  return (
    <section className="contact-form-block">
      <div className="wrap">
        <div className="contact-details">
          <div className="column-wrap">
            <div className="inner-container">
              {heading &&
                <span className="heading">{heading}</span>
              }
              {subheading &&
                <span className="subheading">{subheading}</span>
              }
              <div className="points-of-contact">
                {emailAddress && (
                  <React.Fragment>
                    <span className="contact-item-heading">Email</span>
                    <a href={`mailto:${emailAddress}`} className="contact-item">{emailAddress}</a>
                  </React.Fragment>
                )}
                {phoneNumber && (
                  <React.Fragment>
                    <span className="contact-item-heading">Phone</span>
                    <a href={`tel:${phoneNumber}`} className="contact-item">{phoneNumber}</a>
                  </React.Fragment>
                )}
                {addressPostal && (
                  <React.Fragment>
                    <span className="contact-item-heading">Office</span>
                    <p className="contact-item">{addressPostal}</p>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="contact-form">
          <div className="column-wrap">
            <div className="inner-container">
              {contactFormHeading &&
                <span className="contact-form-heading">{contactFormHeading}</span>
              }
              <div className="form">
                <ContactForm successUrl={`${siteUrl}/thank-you/`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const ContactFormBlock = (props) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            addressPostal
            emailAddress
            phoneNumber
          }
        },
      }
    `}
    render={data => <ContactFormBlockTemplate data={data} {...props} />}
  />
);
