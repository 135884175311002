import React, { Component } from 'react';
import Image from '../components/Common/Image';
import './AccordionBlock.scss';

const isClient = typeof window !== 'undefined';

// Image and Text
const ImageAndText = (props) => {
  const { section } = props;
  const {
    image,
    textPosition,
    text,
    heading,
  } = section;
  return (
    <div className={`section-content image-text ${textPosition}`}>
      <div className="image">
        <Image image={image} />
      </div>
      <div className="text">
        <span className="heading">{heading}</span>
        <div className="inner-text" dangerouslySetInnerHTML={{__html: text}} />
      </div>
    </div>
  );
};

// Centered Text
const CenteredText = (props) => {
  const { section } = props;
  const {
    heading,
    text,
  } = section;
  return (
    <div className="section-content centered-text">
      <span className="heading">{heading}</span>
      <div className="text" dangerouslySetInnerHTML={{__html: text}} />
    </div>
  );
};

// Two Columns Text
const TwoColumnText = (props) => {
  const { section } = props;
  const {
    leftColHeading,
    leftColumnText,
    rightColHeading,
    rightColumnText,
  } = section;
  return (
    <div className="section-content two-column-text">
      <div className="col-left">
        <span className="heading">{leftColHeading}</span>
        <div className="text" dangerouslySetInnerHTML={{__html: leftColumnText}} />
      </div>
      <div className="col-right">
        <span className="heading">{rightColHeading}</span>
        <div className="text" dangerouslySetInnerHTML={{__html: rightColumnText}} />
      </div>
    </div>
  );
};

// Table
const Table = (props) => {
  const { section } = props;
  const { table, cssClassName } = section;
  if (!table) return null;
  const {
    header = null,
    body = null,
  } = table;
  return (
    <div className={`section-content table ${cssClassName}`}>
      <div className="table-header">
        {header && header.map((column, index) => (
          <div className="header-col" dangerouslySetInnerHTML={{__html: column.c}} key={index} />
        ))}
      </div>
      <div className="table-body">
        {body && body.map((row, index) => (
          <div className="body-row" key={index} >
            {row && row.map((column, index) => (
              <div className="body-col" dangerouslySetInnerHTML={{__html: column.c}} key={index} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export class AccordionBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      activeBlockIndex: 0,
      allOpen: props.isOpenOnLoad
    };
  }

  getScrollToOptions = (elem, index) => {
    const {
      isOpen,
      activeBlockIndex,
    } = this.state;

    // Clicked element box. Holds element viewport positions
    const box = elem.getBoundingClientRect();

    const body = document.querySelector('body');
    const docEl = document.documentElement;

    // Gets open element if there is one that is open
    const openEl = document.querySelector('.block-content.open');

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    const clientTop = docEl.clientTop || body.clientTop || 0;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;

    // 120 is the offset from the top of the viewport
    let top = box.top + scrollTop - clientTop - 120;
    const left = box.left + scrollLeft - clientLeft;

    // If there is currently an open element and it is above the
    // element clicked, deduct the open element's height from the top value
    // to scroll to
    if (isOpen && activeBlockIndex < index) {
     top -= openEl.offsetHeight;
    }

    return { top: Math.round(top), left: Math.round(left), behavior: 'smooth' };
  }

  toggleAccordionBlock = (event, index) => {
    const {
      isOpen,
      activeBlockIndex,
    } = this.state;
    const currentActiveBlockIndex = activeBlockIndex;
    if (isClient) window.scrollTo(this.getScrollToOptions(event.target, index));
    this.setState({
      isOpen: currentActiveBlockIndex === index ? !isOpen : true,
      activeBlockIndex: index,
      allOpen: false
    });
  };

  render() {
    const {
      blocks,
      isOpenOnLoad
    } = this.props;
    const {
      activeBlockIndex,
      isOpen,
      allOpen,
    } = this.state;
    return (
      <section className="accordion-block">
        <div className="wrap">
          <div className="accordion-blocks">
            {blocks && blocks.map((block, index) => {
              const { sections } = block;
              return (
                <div key={index} className={`block ${(((activeBlockIndex === index) && isOpen) || allOpen) ? 'open' : ''} ${isOpenOnLoad ? 'always-open' : ''}`}>
                  { /* eslint-disable-next-line */ }
                  <div className="block-header" onClick={(event) => isOpenOnLoad ? null :this.toggleAccordionBlock(event, index)}>
                    <span className="block-title">{block.blockTitle}</span>
                  </div>
                  <div className={`block-content ${(((activeBlockIndex === index) && isOpen) || allOpen) ? 'open' : ''}`}>
                    { // eslint-disable-next-line
                      sections && sections.map((section, index) => {
                        const { type: sectionType } = section;
                        switch (sectionType) {
                          case 'imageAndText':
                            return <ImageAndText section={section} key={index} />;
                          case 'centeredText':
                            return <CenteredText section={section} key={index} />;
                          case 'twoColumnText':
                            return <TwoColumnText section={section} key={index} />;
                          case 'table':
                            return <Table section={section} key={index} />;
                          default:
                            return <div>No section found</div>;
                        }
                      })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
};
