import React from 'react';
import Image from '../components/Common/Image';
import './ImageTextBlock.scss';

export const ImageTextBlock = (props) => {
  const {
    theme = '',
    textPosition = '',
    image,
    heading,
    text,
  } = props;
  return (
    <section className={`image-text-block ${textPosition} ${theme}`}>
      <div className="image-col">
        <div className="inner-image">
          <Image image={image} />
        </div>
      </div>
      <div className="text-col">
        {heading &&
          <h2 className="heading">{heading}</h2>
        }
        <div className="text" dangerouslySetInnerHTML={{__html: text}} />
      </div>
    </section>
  );
};
