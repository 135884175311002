import React, { Component } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { decodeEntities } from '../../utils/htmlParse';

export default class SubscribeForm extends Component {
  state = {
    email: '',
    errorMessage: null,
    submitting: false,
    submitSuccess: false,
    submitError: false,
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { email, submitting } = this.state;
    if (!submitting) {
      this.setState({
        submitting: true,
        submitSuccess: false,
        submitError: false,
      });
      const result = await addToMailchimp(email);
      if (result.result === 'success') {
        this.setState({
          submitSuccess: true,
          submitting: false,
        });
      } else {
        this.setState({
          errorMessage: decodeEntities(result.msg),
          submitError: true,
          submitting: false,
        });
      }
    }
  }

  handleChange = (event) => {
    this.setState({
      email: event.target.value,
    });
  }

  isAlreadySubscribed = () => {
    const { errorMessage } = this.state;
    return errorMessage.indexOf('already subscribed') || errorMessage.indexOf('too many requests');
  }

  render() {
    const {
      email,
      submitting,
      submitSuccess,
      submitError,
    } = this.state;
    return (
      <div className={`form ${submitSuccess ? 'submit-success' : ''}`}>
        { submitSuccess ? (
          <div className="success-message">
            <span>Thanks for subscribing!</span>
          </div>
        ) : (
          <form className={`${submitError ? 'has-error' : ''}`} name="subscribe" onSubmit={event => this.handleSubmit(event)}>
            {submitError && (
              <div className="error-message">
                <span>{this.isAlreadySubscribed ? 'Oops! Looks like you\'re already subscribed to our mailing list.' : `Uh oh! Something went wrong!`}</span>
              </div>
            )}
            <div className="fields">
              <label className="field" htmlFor="name" aria-label="First Name">
                <input type="text" id="name" name="name" placeholder="First Name" />
              </label>
              <label htmlFor="email" className="field" aria-label="Email">
                <input
                  type="email"
                  id="email"
                  disabled={submitting}
                  name="email"
                  placeholder="Email Address*"
                  onChange={event => this.handleChange(event)}
                  value={email}
                  required
                />
              </label>
            </div>
            <button className={`submit-button ${submitting ? 'submitting' : ''}`} aria-label="Send" type="submit" disabled={submitting}>
              <span className="label">{submitting ? 'Submitting...' : 'Go'}</span>
            </button>
          </form>
        )}
      </div>
    );
  }
}
