import React, { Component } from 'react';
import Rellax from 'rellax';

export default class ParallaxElement extends Component {
  componentDidMount() {
    const {
      speed = -2,
      className
    } = this.props;
    this.rellax = new Rellax(`.${className}`, {
      speed,
      center: true,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false
    });
  }
  componentWillUnmount() {
    this.rellax.destroy();
  }
  render() {
    const {
      className,
      children
    } = this.props;
    return (
      <div className={className}>
        {children}
      </div>
    );
  }
}
