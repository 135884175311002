import React from 'react';
import './CenteredTextBlock.scss';

export const CenteredTextBlock = (props) => {
  const {
    theme,
    heading,
    text
  } = props;
  return (
    <section className={`centered-text-block ${theme}`}>
      <div className="wrap">
        {heading &&
          <span className="heading">{heading}</span>
        }
        {text &&
          <div className="text" dangerouslySetInnerHTML={{__html: text}} />
        }
      </div>
    </section>
  );
};
